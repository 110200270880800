import { OAuth2Client } from "@forgerock/javascript-sdk";
import APP_URL_CONSTS from "navigation/AppUrls";
import { Suspense, useCallback, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxhooks";
import { matchUrlToPage } from "../../pages/Pages";
import { clearUser } from "../../redux/slices/userSlice";
import style from "./Layout.module.scss";
import { Navbar, navItem } from "./Navbar/Navbar";
import TopMenu from "./TopMenu/TopMenu";
import Dialog from "components/Dialog/Dialog";
import { LEAVE_DIALOG_TEXT, LEAVE_DIALOG_TITLE } from "utils/messages";
import {
  gipFormPages,
  glFormPages,
  rehabFormPages,
  cicFormPages,
  IPage,
} from "../Breadcrumbs/formPages";

type layoutProps = {
  navItems: navItem[];
  logoutRedirect: string;
  forceShowLogo?: boolean;
  showBackground?: boolean;
};

function Layout({
  navItems,
  logoutRedirect,
  forceShowLogo = false,
  showBackground = true,
}: layoutProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const policyDetls: any = useAppSelector(
    (state: any) => state.ClaimSlice.policyDetls
  );
  const [showLeaveDialog, setShowLeaveDialog] = useState(false);
  const [navigatingTo, setNavigatingTo] = useState("");

  const isUrlAFormJounery = (url: string) => {
    const jounerys = [
      ...gipFormPages,
      ...glFormPages,
      ...rehabFormPages,
      ...cicFormPages,
    ];
    return (
      jounerys.find((formURl: IPage) => {
        return formURl.url
          .toLowerCase()
          .split("/")
          .pop()
          ?.includes(url.toLowerCase().split("/").pop() ?? "");
      }) !== undefined
    );
  };

  const isDialogRequired = useCallback(
    (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      navigatingTo: string
    ) => {
      if (
        isUrlAFormJounery(location.pathname) &&
        navigatingTo !== process.env.REACT_APP_CASHPLAN_REDIRECT_URL
      ) {
        e.preventDefault();
        setShowLeaveDialog(true);
        setNavigatingTo(navigatingTo);
        return;
      } else return;
    },
    [location]
  );

  let title = "Employer Portal";
  let showHelp = false;
  const currentPage = matchUrlToPage(location.pathname, policyDetls.product);
  if (currentPage) {
    title = currentPage.title;
    showHelp = currentPage.showHelpButton;
  }
  return (
    <>
      {
        <header className={style.LayoutHeader}>
          <TopMenu
            title={title}
            showHelp={showHelp}
            showLogo={
              APP_URL_CONSTS.HOMEPAGE === location?.pathname || forceShowLogo
            }
          ></TopMenu>
        </header>
      }
      <div className={style.LayoutSide}>
        <Navbar
          beforeNavigation={isDialogRequired}
          navItems={navItems}
          onLogout={async () => {
            await OAuth2Client.endSession();
            dispatch(clearUser());
            navigate(logoutRedirect);
          }}
        />
      </div>
      {showBackground && (
        <div
          className={style.LayoutBackground}
          data-testid="LayoutBackground"
        ></div>
      )}
      <main className={style.LayoutContent} style={{ paddingTop: "60px" }}>
        <div className={style.CenterSection}>
          <Suspense fallback={<div style={{ margin: "10px" }}>Loading...</div>}>
            <Outlet />
          </Suspense>
        </div>
      </main>
      <Dialog
        open={showLeaveDialog}
        onClose={() => {
          setShowLeaveDialog(false);
        }}
        title={LEAVE_DIALOG_TITLE}
        message={LEAVE_DIALOG_TEXT}
        secndryBtnText="Cancel"
        primaryBtnText="Exit"
        primaryBtnColor="success"
        primaryBtnAction={() => {
          setShowLeaveDialog(false);
          navigate(navigatingTo);
        }}
      />
    </>
  );
}

export default Layout;
