export default {
  LOGIN: "/login",
  CLAIMS: "/claims",
  HOMEPAGE: "/homepage",
  POLICY_SELECTION: "policy-selection",
  TRACKING: "tracking",
  EMPLOYEE_DETAILS: "employee-details",
  DECEASED_DETAILS: "deceased-details",
  ELIGIBILITY_DETAILS: "eligibility-details",
  WORK_DETAILS: "work-details",
  ABSENCE_DETAILS: "absence-details",
  PAYMENT_DETAILS: "payment-details",
  VOC_REHB_SUPPORT: "vocational-rehabilitation-support",
  CLIENT_QUESTIONS: "client-questions",
  CLAIM_SUMMARY: "claim-summary",
  SUBMISION_CONFRIMSN: "submission-confirmation",
  CLAIM_DETAILS: "claim-details",
  ABSENC_MNGT_SPRT: "absence-management-support",
  USERS: "/users",
  ERRORPAGE: "/error",
  BOOKMARK_ERROR: "/bookmark-error",
  OTHER_DETAILS: "other-details",
};
