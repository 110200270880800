import { useCallback, useEffect } from "react";
import { TokenManager } from "@forgerock/javascript-sdk";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../hooks/reduxhooks";

import { appInsights } from "../../ApplicationInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useSearchParams } from "react-router-dom";
import APP_URL_CONSTS from "../../navigation/AppUrls";
import LoginSpinner from "../../components/LoginSpinner/LoginSpinner";
import useUpdateUserInfomation from "../../hooks/User/useUpdateUserInfomation";

const Callback = () => {
  const user = useAppSelector((state) => state.userSlice);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { updateUser } = useUpdateUserInfomation();

  const getTokens = useCallback(async () => {
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    try {
      // do nothing as Forgerock will handle it after the timeout
      if (searchParams.get("error")) {
        return;
      }
      // If the user has a code, state and is not authed then this means that the user has likely required reauthing in FR so we need to handle the token exchange
      if (code && state && !user.isAuthenticated) {
        const tokens = await TokenManager.getTokens({
          login: "redirect",
          query: {
            code: code,
            state: state,
          },
        });
        if (tokens) {
          updateUser();
          navigate(
            process.env.REACT_APP_USE_HOMEPAGE === "true"
              ? APP_URL_CONSTS.HOMEPAGE
              : APP_URL_CONSTS.CLAIMS
          );
          return;
        }
      }
      //If the user is just refreshing the token then the SDK handles the exchange in an Iframe
      if (user.isAuthenticated && code && state) {
        return;
      }
      //If there is is no code/state and the user is not authed then take them to a protected route to restart the auth
      navigate(
        process.env.REACT_APP_USE_HOMEPAGE === "true"
          ? APP_URL_CONSTS.HOMEPAGE
          : APP_URL_CONSTS.CLAIMS
      );
      return;
    } catch (error) {
      if (error instanceof Error && error.name === "TypeError") {
        navigate(APP_URL_CONSTS.BOOKMARK_ERROR);
        return;
      }
      appInsights.trackException({
        error: error as Error,
        severityLevel: SeverityLevel.Error,
      });
      navigate(APP_URL_CONSTS.ERRORPAGE);
      return; 
    }
  }, []);

  useEffect(() => {
    getTokens();
  }, [getTokens]);

  return <LoginSpinner />;
};

export default Callback;
