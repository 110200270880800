import claimsIcon from "../assets/icons/ClaimsIcon.svg";
import HomeIcon from "../assets/icons/House.svg";
import cashPlanIcon from "../assets/icons/speedometer.svg";
import APP_URL_CONSTS from "../navigation/AppUrls";
import { navItem } from "../components/Layout/Navbar/Navbar";
import { applications } from "../types/applications";
import { checkAppAccess } from "../utils/User/Roles";

export type extendNavitem = {
  isEnabled: boolean;
  requiredAccess?: applications[];
} & navItem;

export const NavList: extendNavitem[] = [
  {
    id: "1",
    icon: <img src={HomeIcon} alt="home icon" />,
    expandedSec: <span>Home</span>,
    linkTo: APP_URL_CONSTS.HOMEPAGE,
    openinNewTab: false,
    analyticsName: "Home",
    isEnabled: process.env.REACT_APP_USE_HOMEPAGE === "true",
  },
  {
    id: "2",
    icon: <img src={claimsIcon} alt="Group Protection icon" />,
    expandedSec: <span>Group Protection</span>,
    linkTo: APP_URL_CONSTS.CLAIMS,
    openinNewTab: false,
    analyticsName: "My claims",
    requiredAccess: ["employerPortal"],
    isEnabled: true,
  },
  {
    id: "3",
    icon: <img src={cashPlanIcon} alt="Health Plan 360 icon" />,
    expandedSec: <span>Health Plan 360</span>,
    linkTo: process.env.REACT_APP_CASHPLAN_REDIRECT_URL
      ? process.env.REACT_APP_CASHPLAN_REDIRECT_URL
      : "/error",
    openinNewTab: true,
    analyticsName: "Health Plan 360",
    requiredAccess: ["cashPlan"],
    isEnabled: process.env.REACT_APP_USE_HOMEPAGE === "true",
  },
];

export const filterNavList = (
  UsersAccess: applications[],
  inputNav: extendNavitem[]
): navItem[] => {
  const nav = inputNav.filter(
    (nav) =>
      nav.isEnabled &&
      (nav.requiredAccess
        ? checkAppAccess(UsersAccess, nav.requiredAccess)
        : true)
  );
  return nav;
};
